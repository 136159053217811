import React from 'react'
import { Text, Flex } from '@chakra-ui/react'

export interface StudentTableHeadingItemTypes {
  type: 'students' | 'learning-preference' | 'topic'
  text?: string
  topicName?: string
  topicType?: string
}

export const renderStudentsHeading = (
  { text }: StudentTableHeadingItemTypes,
  index: number
): JSX.Element => {
  return (
    <Flex
      py={5}
      px={6}
      borderWidth='0.5px'
      borderStyle='solid'
      borderColor='primary.2'
      align='center'
      w='258px'
      key={index}
    >
      <Text fontSize='18px' fontWeight={700} color='black.1'>
        {text}
      </Text>
    </Flex>
  )
}

export const renderLearningPreferenceHeading = (
  { text }: StudentTableHeadingItemTypes,
  index: number
): JSX.Element => {
  return (
    <Flex
      px={4}
      borderWidth='0.5px'
      borderStyle='solid'
      borderColor='primary.2'
      w='112px'
      direction='column'
      justify='center'
      key={index}
    >
      <Text fontSize='12px' fontWeight={700} color='rgba(0, 0, 0, 0.88)'>
        {text}
      </Text>
    </Flex>
  )
}

export const renderTopicHeading = (
  { topicName, text, topicType }: StudentTableHeadingItemTypes,
  index: number
): JSX.Element => {
  return (
    <Flex
      px={4}
      borderWidth='0.5px'
      borderStyle='solid'
      borderColor='primary.2'
      w='204px'
      direction='column'
      justify='center'
      key={index}
    >
      <Text
        fontSize='8px'
        fontWeight={600}
        color='secondary.1'
        textTransform='uppercase'
        letterSpacing='1%'
      >
        {topicName}
      </Text>
      <Text fontSize='12px' fontWeight={600} color='rgba(0, 0, 0, 0.88)'>
        {text}
      </Text>
      <Text
        fontSize='8px'
        fontWeight={400}
        color='black.3'
        textTransform='uppercase'
        letterSpacing='1%'
      >
        {topicType}
      </Text>
    </Flex>
  )
}
