import { useState, useEffect } from 'react'
import { Layout } from '../../components/Layout'
import {
  Container,
  Button,
  Flex,
  HStack,
  VStack,
  IconButton,
  Icon,
  useTheme,
  useMediaQuery,
  useDisclosure,
  Box,
  Text,
} from '@chakra-ui/react'
import { BulletinBoard } from '../../components/BulletinBoard'
import { Posts } from '../../components/Post'
import {
  CreatePost,
  CreatePostInput,
  CreateAnnouncementInput,
  CreateAnnouncement,
} from '../../components/Forms/'
import {
  CREATE_POST,
  CREATE_ATTACHMENT,
  CREATE_ANNOUNCEMENT,
  GET_CLASS_POSTS,
  GET_BULLETIN_POSTS,
  UPDATE_POST,
  GET_PENDING_CLASS_REVIEWS,
  GET_CLASS_ASSIGNMENTS_DUE_DATE,
} from '../../client/queries'
import { useMutation, useSubscription } from '@apollo/client'
import { v4 as uuid } from 'uuid'
import { useAlamContext } from '../../client'
import {
  getFullName,
  getFirstAndLastInitial,
  useErrorToast,
  useSuccessToast,
  isMobileDevice,
  formatDateToTimestamp,
  formatDaysWithinToTimestamp,
} from '../../utils'
import {
  RenderForUser,
  Loader,
  Indicator,
} from '../../components/SharedComponents'
import { VarkModal } from '../../components/Modal'
import { AlamAvatar } from '../../components/Avatar'
import { routes } from '../../constants'
import { useHistory } from 'react-router-dom'
import HashLinkObserver from 'react-hash-link'
import { Warning } from '../../components/Icons/Warning'
import { AssignmentDueDate } from '../../types'
import { Announcement, PencilBox, Send } from '../../components/Icons'

export type ClassPageProps = {
  id: string
}

const ClassPage = ({ id }: ClassPageProps): JSX.Element => {
  const { breakpoints } = useTheme()
  const [assignmentsDueDate, setAssignmentsDueDate] = useState<
    AssignmentDueDate[]
  >([])
  const { user, classUser } = useAlamContext()
  const { firstName, lastName } = user
  const name = getFullName({ firstName, lastName })
  const [mobileDevice] = useMediaQuery([`(max-width: ${breakpoints.sm})`])
  const {
    isOpen: postFormIsOpen,
    onClose: postFormOnClose,
    onOpen: postFormOnOpen,
  } = useDisclosure()
  const {
    isOpen: announcementFormIsOpen,
    onClose: announcementFormOnClose,
    onOpen: announcementFormOnOpen,
  } = useDisclosure()
  const {
    isOpen: varkModalIsOpen,
    onClose: varkModalOnClose,
    onOpen: varkModalOnOpen,
  } = useDisclosure()

  const [pendingReviewCount, setPendingReviewCount] = useState(0)
  const history = useHistory()

  const now = new Date()
  const days = 7

  useSubscription(GET_CLASS_ASSIGNMENTS_DUE_DATE, {
    variables: {
      user_id: user.id,
      class_id: id,
      current_date: formatDateToTimestamp(now),
      after_seven_days: formatDaysWithinToTimestamp(days, now),
    },
    onSubscriptionData: ({
      subscriptionData: {
        data: { classes },
      },
    }) => {
      setAssignmentsDueDate(classes.assignments)
    },
  })

  const [updatePost, { loading: postIsUpdating }] = useMutation(UPDATE_POST)
  const [createPost, { loading: postIsSubmitting, error: createPostError }] = useMutation(CREATE_POST)
  console.log(`createPost error: ${createPostError}`)
  const [
    createPostAttachments,
    { loading: attachmentIsSubmitting, error: createPostAttachError },
  ] = useMutation(CREATE_ATTACHMENT)
  console.log(`createPostAttachments error: ${createPostAttachError}`)
  const [
    createAnnouncement,
    { loading: announcementIsSubmitting },
  ] = useMutation(CREATE_ANNOUNCEMENT)
  const { data: postData, loading: postIsLoading } = useSubscription(
    GET_CLASS_POSTS,
    {
      variables: { class_id: id, user_id: user.id },
    }
  )
  const { data: bulletinData, loading: bulletinIsLoading } = useSubscription(
    GET_BULLETIN_POSTS,
    {
      variables: { class_id: id, user_id: user.id },
    }
  )
  type incompleteExamsCount = {
    incompleteExamsCount: {
      aggregate: {
        count: number
      }
    }
  }

  const { data: classReviewSubscriptionData } = useSubscription(
    GET_PENDING_CLASS_REVIEWS,
    {
      variables: {
        class_id: id,
        user_id: user?.id,
      },
    }
  )

  useEffect(() => {
    if (classReviewSubscriptionData) {
      const count = classReviewSubscriptionData?.class?.assignments?.reduce(
        (count: number, assignment: incompleteExamsCount) => {
          return assignment.incompleteExamsCount.aggregate.count + count
        },
        0
      )

      setPendingReviewCount(count)
    }
  }, [classReviewSubscriptionData])

  const posts = postData?.posts || []
  const bulletin = bulletinData?.bulletin || []

  const handleDeletePost = async (
    postId: string,
    onCloseDeletePost: () => void
  ) => {
    try {
      await updatePost({
        variables: {
          id: postId,
          changes: {
            archived_at: 'NOW()',
          },
        },
      })

      useSuccessToast({
        title: 'Post Deleted',
      })
      onCloseDeletePost()
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: 'Delete Post failed' })
    }
  }

  const topNavLeft = [{ type: 'text' as const, text: classUser?.class?.name }]

  const baseTopNavCenter = [
    { type: 'link' as const, url: `/classes/${id}`, text: 'Home' },
    {
      type: 'link' as const,
      url: `/classes/${id}/classwork`,
      text: 'Classwork',
    },
  ]

  const topNavCenter = isMobileDevice()
    ? [
        ...baseTopNavCenter,
        {
          type: 'link' as const,
          url: `/classes/${id}/people`,
          text: 'People',
        },
      ]
    : baseTopNavCenter

  const handleAnnouncementFormOpen = () => {
    postFormOnClose()
    announcementFormOnOpen()
  }

  const handlePostFormOpen = () => {
    announcementFormOnClose()
    postFormOnOpen()
  }

  interface SendPostInput {
    content: string
    files: File[]
    isPinned: boolean
    postId: string
  }

  const submitPost = async ({
    content,
    isPinned: is_pinned,
    files,
    postId,
  }: SendPostInput) => {
    try {
      const r1 = await Promise.all([createPost({
        variables: {
          id: postId,
          content,
          is_pinned,
          user_id: user.id,
          class_id: id,
          is_loading: true,
        },
      })])
      console.log('Ari print: createPost = ', r1)
      if (files && files.length >= 1){
        const r2 = await submitPostAttachments(postId, files)
        console.log('Ari print: submitPostAttachments = ', r2)
      }
      const r3 = await Promise.all([
        updatePost({
          variables: {
            id: postId,
            changes: {
              is_loading: false,
            },
          },
        }),
      ])
      console.log('Ari print: updatePost = ', r3)
    } catch (err) {
      useErrorToast({ message: 'Post creation failed' })
    }
  }

  const onSubmitPost = ({
    content,
    isPinned: is_pinned,
    files,
  }: CreatePostInput) => {
    console.log('Ari print: onSubmitPost ', 'content', content, 'is_pinned', is_pinned, 'files', files)
    const postId = uuid()
    submitPost({ content, isPinned: is_pinned, files, postId })
      .then(() => {
        postFormOnClose()
        useSuccessToast({ message: 'Post created' })
      })
      .catch((err) => {
        useErrorToast({ message: 'Submit Post failed' })
      })
  }

  const submitPostAttachments = async (postId: string, files: File[]) => {
    try {
      return await Promise.all(
        files?.map((file) => {
          console.log('Ari print: submitPostAttachments = ', 'postId', postId, 'file', file)
          return createPostAttachments({
            variables: {
              postId,
              file,
            },
          })
        })
      )
    } catch (err) {
      useErrorToast({ message: 'Upload failed' })
      return err
    }
  }

  const onSubmitAnnouncement = ({
    title,
    content,
    isPinned: is_pinned,
    postSchedule: schedule,
    files,
  }: CreateAnnouncementInput) => {
    const postId = uuid()
    const promises = [
      createAnnouncement({
        variables: {
          id: postId,
          content,
          title,
          is_pinned,
          user_id: user.id,
          class_id: id,
          schedule,
          is_loading: true,
        },
      }),
    ]

    if (files && files.length >= 1) {
      promises.push(submitPostAttachments(postId, files))
    }

    Promise.all(promises)
      .then(() => {
        return updatePost({
          variables: {
            id: postId,
            changes: {
              is_loading: false,
            },
          },
        })
      })
      .then(() => {
        announcementFormOnClose()
        useSuccessToast({ message: 'Announcement created' })
      })
      .catch((err) => {
        useErrorToast({ message: 'Submit Announcement failed' })
      })
  }

  const renderForms = () => {
    if (postFormIsOpen) {
      return (
        <CreatePost
          onClose={postFormOnClose}
          onSubmit={onSubmitPost}
          onDelete={() => {}}
          isSubmitting={
            postIsSubmitting || attachmentIsSubmitting || postIsUpdating
          }
        />
      )
    } else if (announcementFormIsOpen) {
      return (
        <CreateAnnouncement
          onClose={announcementFormOnClose}
          onSubmit={onSubmitAnnouncement}
          isSubmitting={
            announcementIsSubmitting || attachmentIsSubmitting || postIsUpdating
          }
        />
      )
    }
  }

  useEffect(() => {
    if (
      (!user?.learningStyle || user?.learningStyle?.length < 1) &&
      classUser?.role === 'student'
    ) {
      varkModalOnOpen()
    }
  }, [classUser])

  return (
    <Layout
      showSideNav
      topNavLeft={topNavLeft}
      topNavCenter={topNavCenter}
      redirectNonExistingClass
      hasRightNav={routes.class.hasRightNav}
    >
      <Loader loaders={[postIsLoading, bulletinIsLoading]}>
        <Container
          maxWidth={{ base: '100%', md: '456px', lg: '500px', xl: '668px' }}
          py={6}
          px={{ base: 0, lg: 4 }}
          h='auto'
        >
          <Flex direction='column' w='100%' h='100%'>
            <VStack spacing={6} h='100%'>
              <VStack spacing='16px' w='100%'>
                {pendingReviewCount >= 1 && (
                  <Indicator
                    onClick={() => {
                      history.push(`/classes/${id}/classwork`)
                    }}
                    title={`To Complete (${pendingReviewCount})`}
                    subtitle='Please review the assignments or materials for the class'
                  />
                )}

                {!varkModalIsOpen &&
                  (!user?.learningStyle || user?.learningStyle?.length === 0) &&
                  classUser?.role === 'student' && (
                    <HStack
                      borderRadius='8px'
                      border='0.5px solid rgba(23, 5, 61, 0.32)'
                      bg='#FF843F'
                      padding='8px'
                      width='100%'
                      boxShadow='0px 4px 16px rgba(23, 5, 61, 0.08)'
                      spacing={4}
                      alignItems='center'
                      justify='space-between'
                    >
                      <HStack alignItems='center'>
                        <Flex marginLeft='11px'>
                          <Icon
                            as={Warning}
                            color='primary.1'
                            fontSize='18px'
                          />
                        </Flex>
                        <Text
                          color='primary.1'
                          fontWeight='700'
                          fontSize='14px'
                          lineHeight='22px'
                        >
                          Learning Preference Questionnaire
                        </Text>
                      </HStack>
                      <Button
                        width='72px'
                        minW='72px'
                        height='32px'
                        borderRadius='8px'
                        bg='primary.1'
                        padding='16px'
                        onClick={varkModalOnOpen}
                        fontSize='10px'
                        lineHeight='16px'
                        textTransform='uppercase'
                        color='#FFFFFF'
                        textAlign='center'
                        letterSpacing='1.5px'
                      >
                        Review
                      </Button>
                    </HStack>
                  )}

                {user && classUser?.role === 'student' && (
                  <VarkModal
                    isOpen={varkModalIsOpen}
                    onClose={varkModalOnClose}
                    onOpen={varkModalOnOpen}
                    cancelText="I'll do it later"
                  />
                )}

                <BulletinBoard
                  classId={id}
                  posts={bulletin}
                  title='Bulletin Board'
                  assignmentsDueDate={assignmentsDueDate}
                />
              </VStack>

              {mobileDevice ? (
                <RenderForUser roles={['admin', 'teacher', 'creator']}>
                  <HStack
                    spacing={5}
                    w='100%'
                    display={
                      postFormIsOpen || announcementFormIsOpen ? 'none' : 'flex'
                    }
                    px={6}
                    mt={{ base: '0 !important', lg: '24px' }}
                  >
                    <IconButton
                      variant='base.primary'
                      icon={
                        <PencilBox
                          fill='#ffffff'
                          width='20'
                          height='20'
                          viewBox='8 8 24 24'
                        />
                      }
                      aria-label='Create post button'
                      flexGrow={1}
                      fontSize='20px'
                      onClick={handlePostFormOpen}
                    />
                    <IconButton
                      variant='base.primary'
                      icon={
                        <Announcement
                          fill='#ffffff'
                          width='20'
                          height='20'
                          viewBox='8 8 24 24'
                        />
                      }
                      aria-label='Share announcement button'
                      flexGrow={1}
                      fontSize='20px'
                      onClick={handleAnnouncementFormOpen}
                    />
                  </HStack>
                </RenderForUser>
              ) : (
                <RenderForUser roles={['admin', 'teacher', 'creator']}>
                  <HStack
                    spacing={4}
                    w='100%'
                    display={
                      postFormIsOpen || announcementFormIsOpen
                        ? 'none'
                        : 'block'
                    }
                  >
                    <Button
                      variant='base.primary'
                      leftIcon={
                        <PencilBox
                          fill='#ffffff'
                          width='20'
                          height='20'
                          viewBox='8 8 24 24'
                        />
                      }
                      textStyle='body.1'
                      fontWeight='bold'
                      onClick={handlePostFormOpen}
                      width='146px'
                    >
                      Create Post
                    </Button>
                    <Button
                      variant='base.primary'
                      leftIcon={
                        <Announcement
                          fill='#ffffff'
                          width='20'
                          height='20'
                          viewBox='8 8 24 24'
                        />
                      }
                      textStyle='body.1'
                      fontWeight='bold'
                      width='210px'
                      onClick={handleAnnouncementFormOpen}
                    >
                      Share Announcement
                    </Button>
                  </HStack>
                </RenderForUser>
              )}
              <RenderForUser roles={['student']}>
                <Box
                  layerStyle='card.board-updated-shadow'
                  bg='white.1'
                  w='100%'
                  pl={4}
                  pr={2}
                  py={3}
                  onClick={handlePostFormOpen}
                  display={postFormIsOpen ? 'none' : 'block'}
                  cursor='pointer'
                >
                  <Flex w='100%'>
                    <HStack spacing={4} w='100%'>
                      <AlamAvatar
                        size='md'
                        name={getFirstAndLastInitial(user)}
                        src={user.avatar}
                      />

                      <Flex align='center' justify='space-between' w='100%'>
                        <Text
                          color='grey.1'
                          textStyle='body.1'
                          fontSize='16px'
                          lineHeight='24px'
                        >
                          Share something with the class.
                        </Text>

                        <IconButton
                          className='icon-button'
                          aria-label='submit post'
                          bg='none'
                          _hover={{
                            bg: 'none',
                          }}
                          icon={
                            <Send width='16' height='16' viewBox='8 8 24 24' />
                          }
                        />
                      </Flex>
                    </HStack>
                  </Flex>
                </Box>
              </RenderForUser>
              {renderForms()}
              <HashLinkObserver isPageLoading={postIsLoading} />
              <Posts
                posts={posts}
                handleDeletePost={handleDeletePost}
                postFormIsOpen={postFormIsOpen}
                announcementFormIsOpen={announcementFormIsOpen}
              />
            </VStack>
          </Flex>
        </Container>
      </Loader>
    </Layout>
  )
}

export default ClassPage
