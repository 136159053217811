export const GoogleJamboard = () => {
  return (
    <svg
      id='Capa_1'
      enableBackground='new 0 0 512 512'
      height='24'
      width='24'
      viewBox='0 0 512 512'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <circle cx='256' cy='339.5' fill='#fdd929' r='172.5' />
        <path
          d='m428.5 339.5c0-95.112-77.388-172.5-172.5-172.5v345c95.112 0 172.5-77.388 172.5-172.5z'
          fill='#fabe2c'
        />
        <g id='Jamborad_1_'>
          <g>
            <g>
              <path
                d='m428.5 15v152h-172v-152c0-8.284 6.716-15 15-15h142c8.284 0 15 6.716 15 15z'
                fill='#ff641a'
              />
            </g>
          </g>
        </g>
        <path d='m256.5 167h172v165h-172z' fill='#ff9f00' />
      </g>
    </svg>
  )
}
