import React, { useState, useEffect, useMemo } from 'react'
import {
  Heading,
  Flex,
  SimpleGrid,
  useDisclosure,
  Box,
  Icon,
  useMediaQuery,
} from '@chakra-ui/react'
import { NewCard, ClassCard } from '../../components/Card'
import { Layout } from '../../components/Layout'
import { Logo } from '../../components/Logo'
import { ShowMessageModal } from '../../components/Modal'
import { Class as ClassType } from '../../types'
import { JoinClass, JoinClassInput } from '../../components/Forms'
import {
  GET_USER_CLASSES,
  GET_ALL_CLASSES,
  GET_ALL_CLASSES_FOR_USER,
  JOIN_CLASS,
  GetUserClassType,
} from '../../client/queries'
import { useQuery, useMutation, useSubscription } from '@apollo/client'
import { useAlamContext } from '../../client'
import { CreateClass } from './CreateClass'
import { Loader } from '../../components/SharedComponents/Loader'
import { v4 as uuid } from 'uuid'
import { useErrorToast, useSuccessToast } from '../../utils'
import { useHistory } from 'react-router'
import { PlusCircle } from '../../components/Icons'
import { MdAddCircle } from 'react-icons/md'

const left = [{ type: 'reactElement' as const, children: <Logo /> }]

export type ErrorMessage = {
  title: string
  message: string
}

const HomePage = (): JSX.Element => {
  const [showCreateJoinClass, setShowCreateJoinClass] = useState(false)
  const [isLargerThanMobile] = useMediaQuery('(min-width: 544px)')
  const [joinClass] = useMutation(JOIN_CLASS)
  const history = useHistory()
  const [errorMessage, setErrorMessage] = useState<ErrorMessage | null>(null)

  const {
    isOpen: showJoinClass,
    onOpen: onOpenJoinClass,
    onClose: onCloseJoinClass,
  } = useDisclosure()

  const { user, setChatIsOpen } = useAlamContext()
  console.log(`user from context = ${user}`)
  const { data, loading, error: userClassError } = useSubscription(GET_USER_CLASSES, {
    variables: { user_id: user.id },
  })
  console.log(`get user classes:  data = ${data}, loading = ${loading}, error = ${userClassError}`)

  const refetch = () => {}

  // Previous version: # TODO: ASK if want users to be able to join classes they are
  // not currently in from this homepage, and/or add feature where they can join classes
  // they have been invited to, but haven't accepted the email invite yet
  // const { data: allClasses } = useSubscription(GET_ALL_CLASSES) 
  const { data: allClasses, error: allClassesError, loading: allClassesLoading } = useQuery(GET_ALL_CLASSES_FOR_USER, {
    variables: { user_id: user.id },
  })

  console.log(`get all classes for user: data = ${allClasses}, loading = ${allClassesLoading}, error = ${allClassesError}`)
  const classUsers = data?.class_users || []
  const classes = data?.classes || []

  const resetErrorMessage = () => {
    setErrorMessage(null)
  }

  const handleJoinClass = ({ inviteCode }: JoinClassInput) => {
    const userClassJoin = allClasses.classes.filter(
      (userClass: ClassType) => userClass.code === inviteCode
    )
    if (userClassJoin[0]) {
      if (!userClassJoin[0]?.can_send_invite) {
        onCloseJoinClass()

        setErrorMessage({
          title: userClassJoin[0]?.name,
          message:
            'We can’t seem to find this classroom! Please contact the person who invited you to the classroom for more details.',
        })

        return
      }
      try {
        joinClass({
          variables: {
            user_id: user.id,
            class_id: userClassJoin[0].id,
            role: 'student',
            send_invite: false,
          },
        }).then(({ data: { createClassUser: mutationData } }) => {
          if (!mutationData) {
            useErrorToast({
              message: 'You are an existing member of the class.',
            })
          } else {
            useSuccessToast({
              title: 'Join Class',
              message: 'Joined class succesfully',
            })
          }
          onCloseJoinClass()
          history.push(`/classes/${userClassJoin[0].id}`)
        })
      } catch (error) {
        onCloseJoinClass()
        useErrorToast({
          message: 'Join class failed',
        })
      }
    } else {
      onCloseJoinClass()
      setErrorMessage({
        title: 'Class does not exist',
        message:
          "We can't seem to find this classroom!\nPlease contact the person who invited you to the classroom for details",
      })

      return
    }
  }

  const currentClassNames = useMemo(() => {
    if (allClasses) {
      return allClasses.classes.map(({ name }: ClassType) => name)
    }
    return []
  }, [allClasses])

  const existingClassCodes = useMemo(() => {
    if (allClasses) {
      return allClasses.classes.map(({ code }: ClassType) => code)
    }

    return []
  }, [allClasses])

  useEffect(() => {
    setChatIsOpen(false)
  }, [])

  return (
    <>
      <ShowMessageModal
        {...(errorMessage || { title: '', message: '' })}
        isOpen={!!errorMessage}
        onClose={resetErrorMessage}
        hideCloseButton
        textProps={{
          fontSize: '16px',
          lineHeight: '24px',
        }}
      />

      <JoinClass
        isOpen={showJoinClass}
        onClose={onCloseJoinClass}
        onSubmit={handleJoinClass}
        existingClassCodes={existingClassCodes}
      />

      <Layout topNavLeft={left} topNavColor='primary' hideRightIcons={true}>
        <Loader loaders={[loading]}>
          <Flex
            justify='center'
            direction='column'
            maxWidth={{ md: '712px', lg: '960px' }}
            margin='auto'
            pt={{ base: '0', md: '10%' }}
            pb={{ base: '10%', md: '10%' }}
          >
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 4 }}>
              <Flex justify='center'>
                <Box w={{ base: '300px', sm: '232px' }}>
                  <Flex justify='space-between' align='center'>
                    <Heading
                      textStyle='h2'
                      color='primary.1'
                      pb={8}
                      pt={{ base: 5, lg: '0' }}
                      fontWeight='800'
                    >
                      Classes {classes.length >= 1 && `(${classes.length})`}
                    </Heading>

                    {!isLargerThanMobile && (
                      <Box
                        bg='primary.1'
                        p={3}
                        borderRadius='8px'
                        h='100%'
                        position='relative'
                        onClick={() =>
                          setShowCreateJoinClass(!showCreateJoinClass)
                        }
                      >
                        <PlusCircle
                          fill='#ffffff'
                          width='26'
                          height='26'
                          viewBox='7 7 26 26'
                        />
                      </Box>
                    )}
                  </Flex>
                </Box>
              </Flex>
              <Flex justify='center'>
                <Box display='none' w={{ base: '300px', sm: '232px' }} />
              </Flex>
              <Flex justify='center'>
                <Box display='none' w={{ base: '300px', sm: '232px' }} />
              </Flex>
              <Flex justify='center'>
                <Box display='none' w={{ base: '300px', sm: '232px' }} />
              </Flex>
            </SimpleGrid>

            <SimpleGrid
              spacingX='32px'
              spacingY='32px'
              columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 4 }}
            >
              <Flex justify='center'>
                <CreateClass
                  handleRefetchClasses={refetch}
                  showCreateJoinClass={showCreateJoinClass}
                  onOpenJoinClass={onOpenJoinClass}
                  currentClassNames={currentClassNames}
                />
              </Flex>

              <Flex justify='center'>
                <NewCard title='Join Class' onClick={onOpenJoinClass} />
              </Flex>

              {classes?.map((userClass: GetUserClassType) => {
                return (
                  <Flex justify='center' key={userClass.id}>
                    <ClassCard
                      {...userClass}
                      role={userClass?.class_users[0].role}
                      handleRefetchClasses={refetch}
                      currentClassNames={currentClassNames}
                    />
                  </Flex>
                )
              })}
            </SimpleGrid>
          </Flex>
        </Loader>
      </Layout>
    </>
  )
}

export default HomePage
