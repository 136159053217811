import React from 'react'
import { Flex } from '@chakra-ui/react'
import { TopNavItemProps, renderLinkWithBorder } from './index'

interface AssignmentTopNavProps {
  assignmentNav?: TopNavItemProps[]
  isPretopic?: boolean
}

export const AssignmentTopNav = ({
  assignmentNav,
  isPretopic = false,
}: AssignmentTopNavProps): JSX.Element => {
  return (
    <Flex
      h='64px'
      w={isPretopic ? '40%' : '100%'}
      px={4}
      align='center'
      justify='space-around'
    >
      {assignmentNav && (
        <>
          {assignmentNav.map((item, index) => {
            return renderLinkWithBorder(item, index)
          })}
        </>
      )}
    </Flex>
  )
}

export default AssignmentTopNav
