import React from 'react'
import { Input, InputGroup, InputRightElement, Icon } from '@chakra-ui/react'
import { RiCalendar2Fill } from 'react-icons/ri'

export type InputDatePickerProps = {
  value?: string | Date
  onClick: () => void
  w?: string
  textStyle?: string
  color?: string
}

export const InputDatePicker = ({
  value,
  onClick,
  w = '100%',
  textStyle = 'body.1',
  color = 'primary.1',
}: InputDatePickerProps): JSX.Element => {
  const date = value instanceof Date ? value.toISOString() : value

  return (
    <InputGroup onClick={onClick} cursor='pointer'>
      <Input
        value={date ? date : 'No due date'}
        w={w}
        isReadOnly
        cursor='pointer'
        textStyle={textStyle}
        color={color}
      />
      <InputRightElement>
        <Icon as={RiCalendar2Fill} />
      </InputRightElement>
    </InputGroup>
  )
}

export default InputDatePicker
