export const Collapse = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.9996 9.60117L14.9996 11.4012L8.99961 11.4012L8.99961 12.6012L14.9996 12.6012L14.9996 14.4012L17.3996 12.0012L14.9996 9.60117Z'
      fill='currentColor'
    />
    <path
      d='M7.79961 16.8012L6.59961 16.8012L6.59961 7.20117L7.79961 7.20117L7.79961 16.8012Z'
      fill='currentColor'
    />
    <path
      d='M19.2 23.1L4.8 23.1L4.8 24.9L19.2 24.9L19.2 23.1ZM0.9 19.2L0.900001 4.8L-0.899998 4.8L-0.899999 19.2L0.9 19.2ZM4.8 0.899998L19.2 0.899999L19.2 -0.9L4.8 -0.900001L4.8 0.899998ZM23.1 4.8L23.1 19.2L24.9 19.2L24.9 4.8L23.1 4.8ZM19.2 0.899999C21.3539 0.899999 23.1 2.64609 23.1 4.8L24.9 4.8C24.9 1.65198 22.348 -0.9 19.2 -0.9L19.2 0.899999ZM0.900001 4.8C0.900001 2.64609 2.64609 0.899998 4.8 0.899998L4.8 -0.900001C1.65198 -0.900002 -0.899998 1.65198 -0.899998 4.8L0.900001 4.8ZM4.8 23.1C2.64609 23.1 0.9 21.3539 0.9 19.2L-0.899999 19.2C-0.899999 22.348 1.65198 24.9 4.8 24.9L4.8 23.1ZM19.2 24.9C22.348 24.9 24.9 22.348 24.9 19.2L23.1 19.2C23.1 21.3539 21.3539 23.1 19.2 23.1L19.2 24.9Z'
      fill='currentColor'
    />
  </svg>
)
