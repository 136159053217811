import { gql } from '@apollo/client'
import { userDisplayFields } from './fragments'

// export const CREATE_POST = gql`
//   mutation(
//     $id: uuid!
//     $content: String!
//     $is_pinned: Boolean!
//     $user_id: uuid!
//     $class_id: uuid
//     $group_id: uuid
//     $is_loading: Boolean
//   ) {
//     insert_posts_one(
//       object: {
//         id: $id
//         content: $content
//         is_pinned: $is_pinned
//         is_loading: $is_loading
//         inserted_at: "NOW()"
//         updated_at: "NOW()"
//         type: "post"
//         group_id: $group_id
//         class_id: $class_id
//         user: {
//           data: { id: $user_id, inserted_at: "NOW()", updated_at: "NOW()" }
//           on_conflict: { constraint: users_pkey, update_columns: [id] }
//         }
//       }
//     ) {
//       id
//       type
//       content
//       is_pinned
//       inserted_at
//     }
//   }
// `

// export const CREATE_POST = gql`
//   mutation CreatePost (
//     $id: uuid!
//     $content: String!
//     $is_pinned: Boolean!
//     $user_id: uuid!
//     $class_id: uuid
//     $group_id: uuid
//     $is_loading: Boolean
//   ) {
//     insert_posts_one(
//       object: {
//         id: $id
//         content: $content
//         is_pinned: $is_pinned
//         is_loading: $is_loading
//         inserted_at: "NOW()"
//         updated_at: "NOW()"
//         type: "post"
//         group_id: $group_id
//         class_id: $class_id
//         user: {
//           data: { id: $user_id, inserted_at: "NOW()", updated_at: "NOW()" }
//           on_conflict: { constraint: users_pkey, update_columns: [updated_at] }
//         }
//       }
//     ) {
//       id
//       type
//       content
//       is_pinned
//       inserted_at
//     }
//   }
// `

export const CREATE_POST = gql`
  mutation CreatePost (
    $id: uuid!
    $content: String!
    $is_pinned: Boolean!
    $user_id: uuid!
    $class_id: uuid
    $group_id: uuid
    $is_loading: Boolean
  ) {
    insert_posts_one(
      object: {
        id: $id
        content: $content
        is_pinned: $is_pinned
        is_loading: $is_loading
        inserted_at: "NOW()"
        updated_at: "NOW()"
        type: "post"
        group_id: $group_id
        class_id: $class_id
        user_id: $user_id
      }
    ) {
      id
      type
      content
      is_pinned
      inserted_at
    }
  }
`

export const CREATE_ATTACHMENT = gql`
  mutation create_attachment(
    $postId: ID
    $materialId: ID
    $assignmentId: ID
    $submissionId: ID
    $sessionId: ID
    $file: Upload
  ) {
    createAttachment(
      post_id: $postId
      material_id: $materialId
      assignment_id: $assignmentId
      submission_id: $submissionId
      session_id: $sessionId
      file: $file
    ) {
      id
      url
      file
    }
  }
`

export const CREATE_POST_COMMENT = gql`
  mutation($id: uuid!, $content: String!, $user_id: uuid!, $post_id: uuid!) {
    insert_comments_one(
      object: {
        id: $id
        content: $content
        inserted_at: "NOW()"
        updated_at: "NOW()"
        user: {
          data: { id: $user_id, inserted_at: "NOW()", updated_at: "NOW()" }
          on_conflict: { constraint: users_pkey, update_columns: [updated_at] }
        }
        post_id: $post_id
      }
    ) {
      id
      content
    }
  }
`

export const CREATE_FEEDBACK_COMMENT = gql`
  mutation($id: uuid!, $content: String!, $user_id: uuid!, $feedback_id: uuid) {
    insert_comments_one(
      object: {
        id: $id
        content: $content
        inserted_at: "NOW()"
        updated_at: "NOW()"
        user: {
          data: { id: $user_id, inserted_at: "NOW()", updated_at: "NOW()" }
          on_conflict: { constraint: users_pkey, update_columns: [updated_at] }
        }
        assessment_id: $feedback_id
      }
    ) {
      id
      content
    }
  }
`

export const CREATE_ASSIGNMENT_COMMENT = gql`
  mutation(
    $id: uuid!
    $content: String!
    $user_id: uuid!
    $assignment_id: uuid!
  ) {
    insert_comments_one(
      object: {
        id: $id
        content: $content
        inserted_at: "NOW()"
        updated_at: "NOW()"
        user: {
          data: { id: $user_id, inserted_at: "NOW()", updated_at: "NOW()" }
          on_conflict: { constraint: users_pkey, update_columns: [updated_at] }
        }
        assignment_id: $assignment_id
      }
    ) {
      id
      content
    }
  }
`

export const CREATE_MATERIAL_COMMENT = gql`
  mutation(
    $id: uuid!
    $content: String!
    $user_id: uuid!
    $material_id: uuid!
  ) {
    insert_comments_one(
      object: {
        id: $id
        content: $content
        inserted_at: "NOW()"
        updated_at: "NOW()"
        user: {
          data: { id: $user_id, inserted_at: "NOW()", updated_at: "NOW()" }
          on_conflict: { constraint: users_pkey, update_columns: [updated_at] }
        }
        material_id: $material_id
      }
    ) {
      id
      content
    }
  }
`

export const CREATE_SUBMISSION_COMMENT = gql`
  mutation(
    $id: uuid!
    $content: String!
    $user_id: uuid!
    $submission_id: uuid!
    $group_user_id: uuid
  ) {
    insert_comments_one(
      object: {
        id: $id
        content: $content
        inserted_at: "NOW()"
        updated_at: "NOW()"
        user_id: $user_id
        submission_id: $submission_id
        group_user_id: $group_user_id
      }
    ) {
      id
      content
    }
  }
`

export const UPDATE_COMMENT = gql`
  mutation($id: uuid!, $content: String!) {
    update_comments(where: { id: { _eq: $id } }, _set: { content: $content }) {
      returning {
        id
        content
      }
    }
  }
`

export const ARCHIVE_COMMENT = gql`
  mutation($id: uuid!) {
    update_comments(
      where: { id: { _eq: $id } }
      _set: { archived_at: "NOW()" }
    ) {
      affected_rows
      returning {
        id
        content
      }
    }
  }
`

export const CREATE_ANNOUNCEMENT = gql`
  mutation(
    $id: uuid!
    $content: String!
    $title: String!
    $is_pinned: Boolean!
    $user_id: uuid!
    $class_id: uuid!
    $schedule: timestamp!
    $is_loading: Boolean
  ) {
    insert_posts_one(
      object: {
        id: $id
        content: $content
        title: $title
        is_pinned: $is_pinned
        is_loading: $is_loading
        schedule: $schedule
        inserted_at: "NOW()"
        updated_at: "NOW()"
        type: "announcement"
        user: {
          data: { id: $user_id, inserted_at: "NOW()", updated_at: "NOW()" }
          on_conflict: { constraint: users_pkey, update_columns: [updated_at] }
        }
        class: {
          data: { id: $class_id, inserted_at: "NOW()", updated_at: "NOW()" }
          on_conflict: { constraint: classes_pkey, update_columns: [updated_at] }
        }
      }
    ) {
      id
      content
      title
      type
      schedule
      is_pinned
      inserted_at
    }
  }
`

export const UPDATE_POST = gql`
  mutation update_posts($id: uuid!, $changes: posts_set_input) {
    update_posts(where: { id: { _eq: $id } }, _set: $changes) {
      returning {
        id
        title
        type
        schedule
        inserted_at
        archived_at
        content
        is_pinned
      }
    }
  }
`

export const DELETE_ATTACHMENT = gql`
  mutation delete_attachment($id: uuid!) {
    delete_attachments_by_pk(id: $id) {
      id
    }
  }
`

export const GET_GROUP_POSTS = gql`
  ${userDisplayFields}
  subscription get_group_posts($group_id: uuid!, $class_id: uuid!) {
    posts(
      where: {
        group: { id: { _eq: $group_id } }
        archived_at: { _is_null: true }
        is_loading: { _eq: false }
      }
      order_by: [{ inserted_at: desc_nulls_last }]
    ) {
      id
      type
      content
      title
      isPinned: is_pinned
      schedule
      inserted_at
      comments(order_by: [{ updated_at: asc_nulls_last }]) {
        id
        content
        inserted_at
        user {
          ...userDisplayFields
        }
      }
      user {
        ...userDisplayFields
        classUsers: class_users(
          where: {
            class_id: { _eq: $class_id }
            archived_at: { _is_null: true }
          }
        ) {
          role
          id
        }
      }
      attachments {
        id
        url
        file
      }
    }
  }
`

export const GET_CLASS_POSTS = gql`
  ${userDisplayFields}
  subscription get_posts($class_id: uuid!, $user_id: uuid!) {
    posts(
      where: {
        class: { id: { _eq: $class_id } }
        archived_at: { _is_null: true }
        is_loading: { _eq: false }
        _or: [
          { type: { _eq: "post" } }
          {
            _and: [
              { type: { _eq: "announcement" } }
              { schedule: { _lte: "NOW()" } }
            ]
          }
          {
            _and: [
              { type: { _eq: "announcement" } }
              { user_id: { _eq: $user_id } }
            ]
          }
          {
            _and: [
              { type: { _eq: "announcement" } }
              {
                class: {
                  class_users: {
                    user_id: { _eq: $user_id }
                    role: { _in: ["admin", "creator"] }
                  }
                }
              }
            ]
          }
        ]
      }
      order_by: [{ inserted_at: desc_nulls_last }]
    ) {
      id
      type
      content
      title
      isPinned: is_pinned
      schedule
      inserted_at
      comments(
        where: { post_id: { _is_null: false }, archived_at: { _is_null: true } }
        order_by: [{ updated_at: asc_nulls_last }]
      ) {
        id
        content
        inserted_at
        user {
          ...userDisplayFields
        }
      }
      user {
        ...userDisplayFields
        classUsers: class_users(
          where: {
            class_id: { _eq: $class_id }
            archived_at: { _is_null: true }
          }
        ) {
          role
          id
        }
      }
      attachments {
        id
        url
        file
      }
    }
  }
`

export const GET_GROUP_BULLETIN_POSTS = gql`
  ${userDisplayFields}
  subscription get_group_bulletin_posts($group_id: uuid!) {
    bulletin: posts(
      where: {
        group: { id: { _eq: $group_id } }
        is_pinned: { _eq: true }
        archived_at: { _is_null: true }
      }
      order_by: [{ type: asc }, { inserted_at: desc_nulls_last }]
    ) {
      id
      type
      content
      title
      isPinned: is_pinned
      schedule
      inserted_at
      comments(order_by: [{ updated_at: asc_nulls_last }]) {
        id
        content
        inserted_at
        user {
          ...userDisplayFields
        }
      }
      user {
        ...userDisplayFields
      }
      attachments {
        id
        url
        file
      }
    }
  }
`

export const GET_BULLETIN_POSTS = gql`
  ${userDisplayFields}
  subscription get_bulletin_posts($class_id: uuid!, $user_id: uuid!) {
    bulletin: posts(
      where: {
        class: { id: { _eq: $class_id } }
        is_pinned: { _eq: true }
        archived_at: { _is_null: true }
        _or: [
          { type: { _eq: "post" } }
          {
            _and: [
              { type: { _eq: "announcement" } }
              { schedule: { _lte: "NOW()" } }
            ]
          }
          {
            _and: [
              { type: { _eq: "announcement" } }
              { user_id: { _eq: $user_id } }
            ]
          }
          {
            _and: [
              { type: { _eq: "announcement" } }
              {
                class: {
                  class_users: {
                    user_id: { _eq: $user_id }
                    role: { _in: ["admin", "creator"] }
                  }
                }
              }
            ]
          }
        ]
      }
      order_by: [{ type: asc }, { inserted_at: desc_nulls_last }]
    ) {
      id
      type
      content
      title
      isPinned: is_pinned
      schedule
      inserted_at
      comments(
        where: { post_id: { _is_null: false } }
        order_by: [{ updated_at: asc_nulls_last }]
      ) {
        id
        content
        inserted_at
        user {
          ...userDisplayFields
        }
      }
      user {
        id
        ...userDisplayFields
      }
      attachments {
        id
        url
        file
      }
    }
  }
`

export const GET_CLASS = gql`
  query get_class($class_id: uuid!) {
    classes(
      limit: 1
      where: {
        id: { _eq: $class_id }
        _and: { archived_at: { _is_null: true } }
      }
    ) {
      name
    }
  }
`
